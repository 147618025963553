import React from 'react'
import Navbar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'

const Reviews = () => {
    return (
        <>
            <Navbar />
            <div className='thanks-hero-container'>
                <h1 className='mission-h1'>Thank You!</h1>
                <br/>
                <br/>

                <h2 className='footer-h2'>We will get back to you soon and look forward to getting in touch.</h2>
            </div>
            <Footer />
        </>
    )
}
export default Reviews